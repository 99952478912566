import * as React from "react";
import { List, Datagrid, TextField, Show, SimpleShowLayout } from 'react-admin';
import { MyPagination } from './pagination';


export const ManagerList = (props) => (
    <List {...props} title='Менеджеры' pagination={<MyPagination/>}>
        <Datagrid rowClick="show">
            <TextField source="real_id" label="ID" sortable={false} />
            <TextField source="name" label="Имя" sortable={false} />
            <TextField source="dep_id" label="ИД Департамента" sortable={false} />
            <TextField source="dep" label="Департамент" sortable={false} />
        </Datagrid>
    </List>
);

export const ManagerShow = (props) => (
    <Show {...props} title='Менеджер'>
        <SimpleShowLayout>
        <TextField source="real_id" label="ID" sortable={false} />
            <TextField source="name" label="Имя" sortable={false} />
            <TextField source="dep_id" label="ИД Департамента" sortable={false} />
            <TextField source="dep" label="Департамент" sortable={false} />
        </SimpleShowLayout>
    </Show>
);
