import * as React from "react";
import { List, Datagrid, TextField } from 'react-admin';



export const SuppliersList = (props) => (
    <List {...props} title='Поставщики'>
        <Datagrid>
            <TextField source="id" label="ID" />
            <TextField source="name" label="Name" />
        </Datagrid>
    </List>
);
