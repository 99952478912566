import * as React from "react";
import { Fragment } from 'react';
import { List, Datagrid, TextField, Show, SimpleShowLayout, ArrayField, NumberField, DateField, Filter, BooleanInput, TextInput, DateInput } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import ConfirmOrdersButton from './bulkButtons';


const Order2BulkConfirmActionButtons = props => (
    <Fragment>
        <ConfirmOrdersButton label="Confirm orders" {...props} />
    </Fragment>
);


const Orders2Filter = (props) => (
    <Filter {...props}>
        <BooleanInput label="Только новые" source="newonly" alwaysOn />
        <TextInput label="Магазин" source="dep" defaultValue="201" />
        <DateInput label="Дата" source="date_activated" />
    </Filter>
);

function downloadCSV(csv, filename) {
    var fakeLink = document.createElement('a');
    var universalBOM = "\uFEFF";
    fakeLink.style.display = 'none';
    document.body.appendChild(fakeLink);
    var blob = new Blob([universalBOM + csv], { type: 'text/csv;charset=utf-8' });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // Manage IE11+ & Edge
        window.navigator.msSaveOrOpenBlob(blob, filename + ".csv");
    }
    else {
        fakeLink.setAttribute('href', URL.createObjectURL(blob));
        fakeLink.setAttribute('download', filename + ".csv");
        fakeLink.click();
    }
}

const exporter = orders => {
    const fileName = 'orders2.csv';
    const newOrders = [];
    orders.forEach(el => {
        const obj = {
            ...el
        };
        // console.log(obj);
        if (el.order_details) {
            const details = [...obj.order_details];
            // console.log(details);
            details.forEach(d => {
                const o = {
                    ...obj, ...d
                };
                // console.log(o);
                newOrders.push(o);
            });
        } else {
            newOrders.push(el);
        }
        // newOrders.push(obj);
    });
    // console.log(newOrders);
    newOrders.forEach(function (v) {
        delete v.id;
        delete v.order_details;
        v.dlvr_date = v.dlvr_date.substr(0, 10);
        v.dlvr_date_end = v.dlvr_date_end.substr(0, 10);
        v.date_activated = v.date_activated.replace(/T/g, " ").substr(0, 19);
    });
    const converted = convertToCSV(newOrders, {
        delimiter: ';'
    });
    return downloadCSV(converted, fileName);
};

/*
{nrord: 9384564, nrdoc: 24819249, nrmanual: "", dlvr_date: "2020-11-02T00:00:00+02:00",…}
bgcolor: "#ffffff"
clcdep_suppliert: "Sapte Spice SRL"
clcdlvr_time_im_1t: "день"
clcorder_state_im_2t: "Подтверждена"
date_activated: "2020-11-01T17:25:47+02:00"
dep: 401
dep_supplier: 122628
dlvr_date: "2020-11-02T00:00:00+02:00"
dlvr_date_end: "2020-11-03T00:00:00+02:00"
dlvr_time_im_1: 5
doc_at2: ""
nrdoc: 24819249
nrmanual: ""
nrord: 9384564
order_details: [{sc: 122663, cant: 50, suma: 227.5, clcsct: "7 SPICE Franzela alba feliata amb. 400g",…},…]
order_state_im_2: 1
sysfid: 1234
txt_comment: ""
*/

export const Order2List = (props) => (
    <List {...props} title='Заказы' exporter={exporter} bulkActionButtons={<Order2BulkConfirmActionButtons />} filters={<Orders2Filter />} filterDefaultValues={{ newonly: true }}>
        <Datagrid rowClick="show">
            <TextField source="nrord" label="Заказ" />
            <TextField source="nrdoc" label="Документ" />
            <TextField source="clcdep_suppliert" label="Поставщик" />
            <TextField source="dep" label="Магазин" />
            <TextField source="clcorder_state_im_2t" label="Статус" />
            <DateField source="date_activated" label="Создан" />
            <DateField source="dlvr_date" label="Доставить с" />
            <DateField source="dlvr_date_end" label="По" />
            <TextField source="dep_transit" label="Транзитное подразделение" />
            <TextField source="transit_adres" label="Транзитный адрес" />
        </Datagrid>
    </List>
);

export const Order2Show = (props) => (
    <Show {...props} title='Заказ'>
        <SimpleShowLayout>
            <TextField source="nrord" label="Заказ" />
            <TextField source="clcdep_suppliert" label="Поставщик" />
            <TextField source="nrdoc" label="Документ" />
            <TextField source="dep" label="Магазин" />
            <TextField source="clcorder_state_im_2t" label="Статус" />
            <TextField source="date_activated" label="Создан" />
            <TextField source="dlvr_date" label="Доставить с" />
            <TextField source="dlvr_date_end" label="По" />
            <TextField source="dep_transit" label="Транзитное подразделение" />
            <TextField source="transit_adres" label="Транзитный адрес" />
            <ArrayField source="order_details" label="Пункты заказа">
                <Datagrid>
                    <TextField source="sc" label="Код товара" />
                    <TextField source="clcsct" label="Товар" />
                    <TextField source="barcode" label="Штрихкод" />
                    <TextField source="codvechi" label="Старый код" />
                    <NumberField source="price_wo_tva" label="Цена без TVA" />
                    <NumberField source="cant" label="Кол-во" />
                    <NumberField source="suma" label="Сумма" />
                </Datagrid>
            </ArrayField>
        </SimpleShowLayout>
    </Show>
);
