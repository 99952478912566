import * as React from "react";
import { List, Datagrid, TextField, Show, SimpleShowLayout, Filter, NumberField, SelectInput } from 'react-admin';
import { MyPagination } from './pagination';


const choicesShop = [
    { id: 501, name: '501' },
    { id: 502, name: '502' },
    { id: 503, name: '503' },
    { id: 504, name: '504' },
    { id: 505, name: '505' },
];

const PriceFilter = (props) => (
    <Filter {...props}>
        <SelectInput label="WebShop" source="shop_id" choices={choicesShop} allowEmpty={false} optionText="name" optionValue="id" alwaysOn />
    </Filter>
);

export const PriceList = (props) => (
    <List {...props} title='Цены' filters={<PriceFilter />} pagination={<MyPagination/>}>
        <Datagrid rowClick="show">
            <TextField source="id" label="ID товара" sortable={false} />
            <NumberField source="price" label="Цена" sortable={false} />
            <TextField source="price_promo" label="Промо цена" sortable={false} />
            <TextField source="promo_type" label="Тип промо" sortable={false} />
            <NumberField source="shop_id" label="Web магазин" sortable={false} />
        </Datagrid>
    </List>
);

export const PriceShow = (props) => (
    <Show {...props} title='Цена'>
        <SimpleShowLayout>
            <TextField source="id" label="ID товара" />
            <NumberField source="price" label="Цена" />
            <TextField source="price_promo" label="Промо цена" />
            <TextField source="promo_type" label="Тип промо" />
            <NumberField source="shop_id" label="Web магазин" sortable={false} />
        </SimpleShowLayout>
    </Show>
);
