import * as React from "react";
import { List, Datagrid, TextField, Show, SimpleShowLayout } from 'react-admin';
import { MyPagination } from './pagination';



export const BarcodeList = (props) => (
    <List {...props} title='Штрихкоды' pagination={<MyPagination/>}>
        <Datagrid rowClick="show">
            <TextField source="product_id" label="ID товара" sortable={false} />
            <TextField source="barcode" label="Штрихкод" sortable={false} />
        </Datagrid>
    </List>
);

export const BarcodeShow = (props) => (
    <Show {...props} title='Штрихкоды'>
        <SimpleShowLayout>
            <TextField source="product_id" label="ID товара" />
            <TextField source="barcode" label="Штрихкод" sortable={false} />
        </SimpleShowLayout>
    </Show>
);
