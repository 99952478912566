import * as React from "react";
import { List, Datagrid, TextField, TextInput, SelectField, SelectInput, ImageField, ImageInput, DateField, DateInput, Show, SimpleShowLayout, Edit, SimpleForm, Filter } from 'react-admin';
// import Box from '@material-ui/core/Box';
// import Card from '@material-ui/core/Card'
// import Typography from '@material-ui/core/Typography';
import { Box, Card, CardContent, Typography } from '@material-ui/core';

/*
    Firstname string     `json:"firstname" example:"Vasile"`
    Lastname  string     `json:"lastname" example:"Lupu"`
    Phone     string     `json:"phone" example:"079123456"`
    Email     string     `json:"email,omitempty" example:"vasile.lupu@mail.md"`
    IsMale    bool       `json:"is_male,omitempty" example:"true"`
    Birhtdate *time.Time `json:"birthdate,omitempty" example:"1996-01-02T00:00:00Z"`
    Barcode   string     `json:"barcode,omitempty"`   // ignored when calling card registration
    Signature string     `json:"signature,omitempty"` // base64 encoded jpeg image
*/

// const choices = [
//     { id: true, name: 'М' },
//     { id: false, name: 'Ж' },
// ];

const choicesFulls = [
    { id: true, name: 'Мужской' },
    { id: false, name: 'Женский' },
];

const Empty = ({ basePath, resource }) => (
    <Box textAlign="left" m={1}>
        <LoyaltycardFilter />
        <Card>
            <CardContent>
                <Typography variant="body2">
                    No results found
                </Typography>
            </CardContent>
        </Card>
    </Box>
);

const LoyaltycardFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Телефон" source="phone" alwaysOn />
        <TextInput label="E-mail" source="email"  alwaysOn/>
        <TextInput label="Штрихкод" source="barcode" alwaysOn/>
    </Filter>
);

export const LoyaltycardList = (props) => (
    <List empty={<Empty />} {...props} title='Карты лояльности' filters={<LoyaltycardFilter />}>
        <Datagrid rowClick="show">
            <TextField source="firstname" label="Имя" />
            <TextField source="lastname" label="Фамилия" />
            <TextField source="phone" label="Телефон" />
            <TextField source="email" label="E-mail" />
            <SelectField source="is_male" label="Пол" choices={choicesFulls} optionText="name" optionValue="id" />
            <DateField source="birthdate" label="Дата рождения" />
            <TextField source="barcode" label="Штрихкод" />
            <ImageField source="signature" label="Подпись" />
        </Datagrid>
    </List>
);

export const LoyaltycardShow = (props) => (
    <Show {...props} title='Карта лояльности'>
        <SimpleShowLayout>
            <TextField source="firstname" label="Имя" />
            <TextField source="lastname" label="Фамилия" />
            <TextField source="phone" label="Телефон" />
            <TextField source="email" label="E-mail" />
            <SelectField source="is_male" label="Пол" choices={choicesFulls} optionText="name" optionValue="id" />
            <DateField source="birthdate" label="Дата рождения" />
            <TextField source="barcode" label="Штрихкод" />
            <ImageField source="signature" label="Подпись" />
        </SimpleShowLayout>
    </Show>
);

export const LoyaltycardEdit = (props) => (
    <Edit {...props} title='Карта лояльности'>
        <SimpleForm>
            <TextInput source="firstname" label="Имя" />
            <TextInput source="lastname" label="Фамилия" />
            <TextInput source="phone" label="Телефон" />
            <TextInput source="email" label="E-mail" />
            <SelectInput source="is_male" label="Пол" choices={choicesFulls} optionText="name" optionValue="id" />
            <DateInput source="birthdate" label="Дата рождения" />
            <TextField source="barcode" label="Штрихкод" />
            <ImageField source="signature" label="Подпись" />
            <ImageInput source="signature" label="Подпись" accept="image/jpeg">
                <ImageField source="src" title="title" />
            </ImageInput>
        </SimpleForm>
    </Edit>
);

// export const LoyaltycardCreate = (props) => (
//     <Create {...props} title='Карта лояльности'>
//         <SimpleForm>
//             <TextInput source="firstname" label="Имя" />
//             <TextInput source="lastname" label="Фамилия" />
//             <TextInput source="phone" label="Телефон" />
//             <TextInput source="email" label="E-mail" />
//             <SelectInput source="is_male" label="Пол" choices={choicesFulls} optionText="name" optionValue="id" />
//             <DateInput source="birthdate" label="Дата рождения" />
//             {/* <TextField source="barcode" label="Штрихкод" /> */}
//             <ImageInput source="signature" label="Подпись" accept="image/jpeg">
//                 <ImageField source="src" title="title" />
//             </ImageInput>
//         </SimpleForm>
//     </Create>
// );