import * as React from "react";
import { List, Datagrid, TextField, Show, SimpleShowLayout, Filter, NumberField, SelectInput } from 'react-admin';
import { MyPagination } from './pagination';

const choicesShop = [
    { id: 501, name: '501' },
    { id: 502, name: '502' },
    { id: 503, name: '503' },
    { id: 504, name: '504' },
    { id: 505, name: '505' },
];

const StockFilter = (props) => (
    <Filter {...props}>
        <SelectInput label="WebShop" source="shop_id" choices={choicesShop} allowEmpty={false} optionText="name" optionValue="id" alwaysOn />
    </Filter>
);

export const StockList = (props) => (
    <List {...props} title='Остатки' filters={<StockFilter />} pagination={<MyPagination/>}>
        <Datagrid rowClick="show">
            <TextField source="id" label="ID товара" sortable={false} />
            <TextField source="stock" label="Остаток" sortable={false} />
            <NumberField source="shop_id" label="ID магазина" sortable={false} />
        </Datagrid>
    </List>
);

export const StockShow = (props) => (
    <Show {...props} title='Остаток'>
        <SimpleShowLayout>
            <TextField source="id" label="ID товара" />
            <TextField source="stock" label="Остаток" />
            <NumberField source="shop_id" label="ID магазина" />
        </SimpleShowLayout>
    </Show>
);
