import * as React from "react";
import { List, Datagrid, TextField, Show, SimpleShowLayout } from 'react-admin';
import { MyPagination } from './pagination';



export const DepartmentList = (props) => (
    <List {...props} title='Подразделения' pagination={<MyPagination/>}>
        <Datagrid rowClick="show">
            <TextField source="id" label="ID магазина" sortable={false} />
            <TextField source="name" label="Наименование" sortable={false} />
            <TextField source="address" label="Адрес" sortable={false} />
        </Datagrid>
    </List>
);

export const DepartmentShow = (props) => (
    <Show {...props} title='Подразделения'>
        <SimpleShowLayout>
            <TextField source="id" label="ID товара" />
            <TextField source="name" label="Наименование" sortable={false} />
            <TextField source="address" label="Адрес" sortable={false} />
        </SimpleShowLayout>
    </Show>
);
