import * as React from "react";
import { List, Datagrid, TextField, Show, SimpleShowLayout, ArrayField, NumberField, Filter, TextInput, DateInput } from 'react-admin';


const ManyOrdersFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Магазин" source="dep" defaultValue="201" />
        <DateInput label="Дата" source="date_activated" />
    </Filter>
);

export const ManyOrderList = (props) => (
    <List {...props} title='Tecnet orders' filters={<ManyOrdersFilter />} filterDefaultValues={{ newonly: true }}>
        <Datagrid rowClick="show">
            <NumberField source="id" label="Supplier ID" />
            <TextField source="name" label="Name" />
            <TextField source="count" label="Order count" />
        </Datagrid>
    </List>
);

export const ManyOrderShow = (props) => (
<Show {...props} title='Tecnet order'>
        <SimpleShowLayout>
        <TextField source="id" label="Supplier ID" />
            <TextField source="name" label="Name" />
            <TextField source="count" label="Count" />
        <ArrayField source="orders" label="Заказы">
            <Datagrid>
                <TextField source="nrord" label="Заказ" />
                {/* <TextField source="clcdep_suppliert" label="Поставщик" /> */}
                <TextField source="nrdoc" label="Документ" />
                <TextField source="dep" label="Магазин" />
                {/* <TextField source="clcorder_state_im_2t" label="Статус" /> */}
                {/* <TextField source="date_activated" label="Создан" /> */}
                <TextField source="dlvr_date" label="Доставить с" />
                <TextField source="dlvr_date_end" label="По" />
            </Datagrid>
        </ArrayField>
        </SimpleShowLayout>
    </Show>
);
