import * as React from "react";
import {
    Button,
    useUpdateMany,
    useRefresh,
    useNotify,
    useUnselectAll,
} from 'react-admin';
import { Check } from '@material-ui/icons';

const ConfirmOrdersButton = ({ selectedIds }) => {
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const [updateMany, { loading }] = useUpdateMany(
        'orders',
        selectedIds,
        { confirmed: true },
        {
            onSuccess: () => {
                refresh();
                notify('Orders updated');
                unselectAll('orders');
            },
            onFailure: error => notify('Error: orders not updated', 'warning'),
        }
    );

    return (
        <Button
            label="Confirm"
            disabled={loading}
            onClick={updateMany}
        >
            <Check />
        </Button>
    );
};

export default ConfirmOrdersButton;