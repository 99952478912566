import * as React from "react";
import { List, Datagrid, TextField, TextInput, SelectInput, Show, NumberField, SimpleShowLayout, Filter } from 'react-admin';
import { MyPagination } from './pagination';

const choicesShop = [
    { id: 501, name: '501' },
    { id: 502, name: '502' },
    { id: 503, name: '503' },
    { id: 504, name: '504' },
    { id: 505, name: '505' },
];

const ProductFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <SelectInput label="WebShop" source="shop_id" choices={choicesShop} allowEmpty={false} optionText="name" optionValue="id" alwaysOn />
    </Filter>
);

export const ProductList = (props) => (
    <List {...props} title='Товары' filters={<ProductFilter />} pagination={<MyPagination/>}>
        <Datagrid rowClick="show">
            <TextField source="id" label="ID" sortable={false} />
            <TextField source="name" label="Наименование RO" sortable={false} />
            <TextField source="name_ru" label="Наименование RU" sortable={false} />
            <TextField source="prod_type" label="Тип" sortable={false} />
            <TextField source="unit" label="ед.изм RO" sortable={false} />
            <TextField source="unit_ru" label="ед.изм RU" sortable={false} />
            <NumberField source="shop_id" label="ShopID" sortable={false} />
        </Datagrid>
    </List>
);

export const ProductShow = (props) => (
    <Show {...props} title='Товар'>
        <SimpleShowLayout>
            <TextField source="id" label="ID" />
            <TextField source="name" label="Наименование RO" />
            <TextField source="name_ru" label="Наименование RU" />
            <TextField source="prod_type" label="Тип" />
            <TextField source="unit" label="ед.изм RO" />
            <TextField source="unit_ru" label="ед.изм RU" />
            <NumberField source="shop_id" label="ShopID" sortable={false} />
        </SimpleShowLayout>
    </Show>
);
