import { fetchUtils, cacheDataProviderProxy } from 'react-admin';
import { stringify } from 'query-string';

const apiUrl = '/api/v1';
// const httpClient = fetchUtils.fetchJson;

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);
    // console.log(url, options);
    return fetchUtils.fetchJson(url, options);
};

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */

function convertFileToBase64(file) {
    // console.log(typeof (file));
    if(typeof (file) === "string") {
        return new Promise((resolve, reject) => {
            resolve(file);
        })
    }
    if(typeof (file) === "undefined") {
        return new Promise((resolve, reject) => {
            resolve(file);
        })
    }
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;

        reader.readAsDataURL(file.rawFile);
    })
}


function mymap(resource, data) {
    // console.log(resource, data);
    if (resource === "groups") {
        return data.map(r => ({ ...r, id: r.group_id }));
    }
    else if (resource === "prices") {
        return data.map(r => ({ ...r, id: r.product_id }));
    }
    else if (resource === "stocks") {
        return data.map(r => ({ ...r, id: r.product_id }));
    }
    else if (resource === "orders") {
        return data.map(r => ({ ...r, id: r.nrdoc }));
    }
    else if (resource === "orders2") {
        return data.map(r => ({ ...r, id: r.nrdoc }));
    }
    else if (resource === "barcodes") {
        return data.map(r => ({ ...r, id: r.barcode }));
    }
    else if (resource === "managers") {
        return data.map(r => ({ ...r, real_id: r.id, id: r.id+":"+r.dep_id }));
    }
    return data;
}
function myfix(resource, data) {
    // console.log(resource, data);
    if (resource === "groups") {
        return { ...data, id: data.group_id };
    }
    else if (resource === "prices") {
        return { ...data, id: data.product_id };
    }
    else if (resource === "stocks") {
        return { ...data, id: data.product_id };
    }
    else if (resource === "orders") {
        return { ...data, id: data.nrdoc };
    }
    else if (resource === "orders2") {
        return { ...data, id: data.nrdoc };
    }
    else if (resource === "barcodes") {
        return { ...data, id: data.barcode };
    }
    return data;
}

function fixDate(resource, data) {
    // console.log(resource, data);
    if (resource === "loyaltycards" && typeof(data.birthdate) != "undefined" && data.birthdate.length <= 10) {
        data.birthdate = data.birthdate + "T00:00:00+02:00";
        return data;
    }
    return data;
}

const provider = {
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            ...fetchUtils.flattenObject(params.filter),
            _sort: field,
            _order: order,
            _start: (page - 1) * perPage,
            _end: page * perPage,
        };
        const url = `${apiUrl}/${resource}/?${stringify(query)}`;
        // const url = `${apiUrl}/${resource}/`;

        // console.log(resource, params);
        return httpClient(url).then(({ headers, json }) => (
            {
                data: mymap(resource, json.data),
                total: json.total,
            }
        ));
    },
    getOne: (resource, params) => {
        // console.log(resource, params);
        const url = `${apiUrl}/${resource}/${params.id}`;

        return httpClient(url).then(({ headers, json }) => (
            // console.log(json.data,json.data.length)
            {
                data: json.data,
            }
        ));
    },
    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ data: json }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json,
            total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }));
    },
    update: (resource, params) => {
        if (resource === "loyaltycards") {
            return Promise.resolve(convertFileToBase64(params.data.signature))
                .then(base64file => {
                    params.data.signature = base64file;
                    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
                        method: 'PUT',
                        body: JSON.stringify(fixDate(resource, params.data)),
                    }).then(({ json }) => ({ data: myfix(resource, json.data) }))
                })
        }
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(fixDate(resource, params.data)),
        }).then(({ json }) => ({ data: myfix(resource, json.data) }))
    },
    updateMany: (resource, params) => {
        // console.log(resource, params);
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json.data }));
    },
    create: (resource, params) => {
        if (resource === "loyaltycards") {
            return Promise.resolve(convertFileToBase64(params.data.signature))
                .then(base64file => {
                    params.data.signature = base64file;
                    return httpClient(`${apiUrl}/${resource}/`, {
                        method: 'POST',
                        body: JSON.stringify(fixDate(resource, params.data)),
                    }).then(({ json }) => ({ data: json.data }))
                })
        }

        return httpClient(`${apiUrl}/${resource}/`, {
            method: 'POST',
            body: JSON.stringify(fixDate(resource, params.data)),
        }).then(({ json }) => ({
            data: json.data,
        }))
    },
    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json })),
    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'DELETE',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },
};

// export default provider;
export default cacheDataProviderProxy(provider);