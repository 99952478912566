import * as React from "react";
import { List, Datagrid, TextField, TextInput, Show, SimpleShowLayout, Filter, DateField } from 'react-admin';
import { MyPagination } from './pagination';


const EmployeeExFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

export const EmployeeExList = (props) => (
    <List {...props} title='Сотрудники' filters={<EmployeeExFilter />} pagination={<MyPagination/>}>
        <Datagrid rowClick="show">
            <TextField source="id" label="ID" sortable={false} />
            <TextField source="name" label="Имя" sortable={false} />
            <TextField source="position" label="Должность" sortable={false} />
            <TextField source="dep" label="Департамент" sortable={false} />
            <DateField source="last_data_angajat" label="Принят" sortable={false} />
            <DateField source="last_data_transferat" label="Переведен" sortable={false} />
            <DateField source="last_data_eliberat" label="Уволен" sortable={false} />
            <TextField source="manager" label="Менеджер" sortable={false} />
            <TextField source="manager_regional" label="Региональный менеджер" sortable={false} />
        </Datagrid>
    </List>
);

export const EmployeeExShow = (props) => (
    <Show {...props} title='Сотрудник'>
        <SimpleShowLayout>
        <TextField source="id" label="ID" sortable={false} />
            <TextField source="name" label="Имя" sortable={false} />
            <TextField source="position" label="Должность" sortable={false} />
            <TextField source="dep_id" label="ИД Департамента" sortable={false} />
            <TextField source="dep" label="Департамент" sortable={false} />
            <DateField source="last_data_angajat" label="Принят" sortable={false} />
            <DateField source="last_data_transferat" label="Переведен" sortable={false} />
            <DateField source="last_data_eliberat" label="Уволен" sortable={false} />
            <TextField source="manager_id" label="ИД Менеджера" sortable={false} />
            <TextField source="manager" label="Менеджер" sortable={false} />
            <TextField source="manager_regional_id" label="ИД Регионального менеджера" sortable={false} />
            <TextField source="manager_regional" label="Региональный менеджер" sortable={false} />
        </SimpleShowLayout>
    </Show>
);
