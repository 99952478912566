import * as React from "react";
import { Admin, Resource } from 'react-admin';
// import { ListGuesser } from 'react-admin';

import dataProvider from './dataProvider';
import authProvider from './authProvider';
// import { GroupList } from './groups';
import { ProductList, ProductShow } from './products';
import { PriceList, PriceShow } from './prices';
import { StockList, StockShow } from './stocks';
import { BarcodeList, BarcodeShow } from './barcodes';
import { OrderList, OrderShow } from './orders';
import { Order2List, Order2Show } from './orders2';
import { UserList, UserShow, UserEdit, UserCreate } from './users';
import { LogList, LogShow } from './logs';
import { EmployeeList, EmployeeShow } from './employees';
import { EmployeeExList, EmployeeExShow } from './employeesex';
import { ManagerList, ManagerShow } from './managers';
import { SuppliersList, } from './suppliers';
import { ManyOrderList, ManyOrderShow } from './manyorders';
import { ShopList, ShopShow } from './shops';
import { DepartmentList, DepartmentShow } from './deps';
import { LoyaltycardList, LoyaltycardShow } from './loyaltycard'
import { Order1209List } from './orders1209'
import { Order12099List } from './orders12099'
// import { LoyaltycardList, LoyaltycardShow, LoyaltycardEdit, LoyaltycardCreate } from './loyaltycard'
// import { MyDashboard } from "./Dashboard";
import UserIcon from '@material-ui/icons/Group';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import polyglotI18nProvider from 'ra-i18n-polyglot'; // install the package
import englishMessages from 'ra-language-english'; // install the package

const i18nProvider = polyglotI18nProvider(() => englishMessages, 'en', { allowMissing: true });

const App = () => (
    <Admin i18nProvider={i18nProvider} dataProvider={dataProvider} authProvider={authProvider} disableTelemetry={true}>
        {permissions => [
            // permissions === 'admin'
            //     ?
            //     <MyDashboard />
            //     : null,
            permissions === 'admin'
                ?
                <Resource name="users" options={{ label: 'Пользователи' }} list={UserList} show={UserShow} edit={UserEdit} create={UserCreate} icon={UserIcon} />
                : null,
            permissions === 'admin'
                ?
                <Resource name="logs" options={{ label: 'Логи' }} list={LogList} show={LogShow} icon={RecentActorsIcon} />
                : null,
              <Resource name="orders"       options={{ label: 'Заказы' }}           list={OrderList}       show={OrderShow}       icon={FormatListNumberedIcon} />
            , <Resource name="orders2"       options={{ label: 'Заказы2' }}         list={Order2List}      show={Order2Show}       icon={FormatListNumberedIcon} />
            , <Resource name="shops"        options={{ label: 'Магазины' }}         list={ShopList}        show={ShopShow}        icon={HomeWorkIcon} />
            , <Resource name="deps"         options={{ label: 'Подразделения' }}    list={DepartmentList}  show={DepartmentShow}  icon={HomeWorkIcon} />
            , <Resource name="loyaltycards" options={{ label: 'Карты лояльности' }} list={LoyaltycardList} show={LoyaltycardShow} icon={LoyaltyIcon} />
            , <Resource name="products"     options={{ label: 'Товары' }}           list={ProductList}     show={ProductShow}     icon={ShoppingCartIcon} />
            , <Resource name="barcodes"     options={{ label: 'Штрихкоды' }}        list={BarcodeList}     show={BarcodeShow}     icon={HorizontalSplitIcon} />
            , <Resource name="prices"       options={{ label: 'Цены' }}             list={PriceList}       show={PriceShow}       icon={AttachMoneyIcon} />
            , <Resource name="stocks"       options={{ label: 'Остатки' }}          list={StockList}       show={StockShow}       icon={DataUsageIcon} />
            , <Resource name="employees"    options={{ label: 'Сотрудники' }}       list={EmployeeList}    show={EmployeeShow}    icon={UserIcon} />
            , <Resource name="employeesex"  options={{ label: 'Сотрудники2' }}      list={EmployeeExList}  show={EmployeeExShow}  icon={UserIcon} />
            , <Resource name="managers"     options={{ label: 'Менеджеры' }}        list={ManagerList}     show={ManagerShow}     icon={UserIcon} />
            , <Resource name="managers"     options={{ label: 'Менеджеры' }}        list={ManagerList}     show={ManagerShow}     icon={UserIcon} />
            , <Resource name="suppliers"     options={{ label: 'Suppliers' }}       list={SuppliersList}                          icon={UserIcon} />
            , <Resource name="manyorders"     options={{ label: 'Tecnet orders' }}  list={ManyOrderList}   show={ManyOrderShow}   icon={UserIcon} />
            , <Resource name="orders1209"     options={{ label: 'Tecnet orders 1209' }}  list={Order1209List} icon={UserIcon} />
            , <Resource name="orders12099"     options={{ label: 'Tecnet orders 12099' }}  list={Order12099List} icon={UserIcon} />
            //<Resource name="group" options={{ label: 'Groups' }} list={GroupList} />
        ]}
    </Admin>
);
export default App;