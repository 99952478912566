import * as React from "react";
import { List, Datagrid, TextField, DateField, DateInput, TextInput, NumberField } from 'react-admin';


// NRDOC,PARENT_NRDOC,SYSFID,DATAMANUAL,NRMANUAL,MAG,POST,MARFA_COD,MARFA_NAME,BARCOD,CANT,PRET,SUMA,SUMA_FARA_TVA,SUMA_TVA,CLC_STATUS_D,STATUS_COMMENT

const Order1209Filters = [
    <TextInput label="nrdoc" source="nrdoc" alwaysOn />,
    <TextInput label="nrmanual" source="nrmanual" alwaysOn />,
    <TextInput label="post" source="post" alwaysOn />,
    <DateInput label="data" source="datamanual" alwaysOn />,
  ];
  

export const Order1209List = (props) => (
    <List {...props} title='Tecnet orders 1209' filters={Order1209Filters}>
        <Datagrid>
        <TextField source="id" label="id" sortable={false}/>
        <NumberField source="NRDOC" label="nrdoc" sortable={false} options={{useGrouping:false}}/>
            <NumberField source="PARENT_NRDOC" label="parent" sortable={false} options={{useGrouping:false}}/>
            <NumberField source="SYSFID" label="sysfid" sortable={false} options={{useGrouping:false}}/>
            <DateField source="DATAMANUAL" label="data" sortable={false}/>
            <TextField source="NRMANUAL" label="nrmanual" sortable={false}/>
            <NumberField source="MAG" label="mag" sortable={false}/>
            <NumberField source="POST" label="post" sortable={false} options={{useGrouping:false}}/>
            <NumberField source="MARFA_COD" label="marfa_cod" sortable={false} options={{useGrouping:false}}/>
            <TextField source="MARFA_NAME" label="marfa_name" noWrap={true} sortable={false}/>
            <TextField source="BARCOD" label="barcod" sortable={false}/>
            <NumberField source="CANT" label="cant" sortable={false}/>
            <NumberField source="PRET" label="pret" sortable={false}/>
            <NumberField source="SUMA" label="suma" sortable={false}/>
            <NumberField source="SUMA_FARA_TVA" label="suma_fara_tva" sortable={false}/>
            <NumberField source="SUMA_TVA" label="suma_tva" sortable={false}/>
            <TextField source="CLC_STATUS_D" label="clc_status_d" sortable={false}/>
            <TextField source="STATUS_COMMENT" label="status_comment" sortable={false}/>
        </Datagrid>
    </List>
);

// export const Order1209Show = (props) => (
//     <Show {...props} title='Заказы'>
//         <SimpleShowLayout>
//         <TextField source="NRDOC" />
//             <TextField source="PARENT_NRDOC" />
//             <TextField source="SYSFID" />
//             <TextField source="DATAMANUAL" />
//             <TextField source="NRMANUAL" />
//             <TextField source="MAG" />
//             <TextField source="POST" />
//             <TextField source="MARFA_COD" />
//             <TextField source="MARFA_NAME" />
//             <TextField source="BARCOD" />
//             <TextField source="CANT" />
//             <TextField source="PRET" />
//             <TextField source="SUMA" />
//             <TextField source="SUMA_FARA_TVA" />
//             <TextField source="SUMA_TVA" />
//             <TextField source="CLC_STATUS_D" />
//             <TextField source="STATUS_COMMENT" />
//         </SimpleShowLayout>
//     </Show>
// );